import React, { useCallback, useEffect, useRef, useState } from "react";
import { Marker, Popup, MapContainer, TileLayer, useMap, ZoomControl } from "react-leaflet";
import ImageModal from "./ImageModal";
import { motion } from "framer-motion";
import PollockProjectLogo from "./PollockProjectLogo";
import SplatterLogoDropdown from "./SplatterLogoDropdown";
import MobileHeader from "./MobileHeader";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import { ref, getDownloadURL } from "firebase/storage";
import { getDocs, collection, where, query } from "firebase/firestore";
import { db, storage } from "../firebase_setup/firebase.config";
import PKFoundationFooter from "./PKFoundationFooter";
import "../CSS/responsive.css";

const Map2 = () => {
  const [results, setResults] = useState([]);
  const [searchedForArtworks, setSearchedForArtworks] = useState([]);
  const [imageClicked, setImagedClicked] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [individualImageUrl, setIndividualUrl] = useState(null);
  const [hovering, setHovering] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  const firstImageRef = useRef(null);
  const scrollToTopRef = useRef(null);

  useEffect(() => {
    async function fetchData() {
      const pollockWorksRef = collection(db, "pollockWorks");
      const workInfo = await query(pollockWorksRef, where("isArtwork", "==", true));

      const querySnapshot = await getDocs(workInfo);

      const promises = querySnapshot.docs.map(async (doc) => {
        const individualWork = doc.data();
        let url = individualWork.imagePath ? await getDownloadURL(ref(storage, individualWork.imagePath)) : "";

        return {
          id: doc.id,
          ...doc.data(),
          imageUrl: url,
        };
      });

      let finalPromise = await Promise.all(promises);
      setResults(finalPromise);
    }
    fetchData();
  }, []);

  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref,
      behavior: "smooth",
    });
  };

  const handleMuseumClick = useCallback(
    (selectedMuseum) => {
      try {
        let searchedWorks = results.filter((artwork) =>
          artwork.museum.toLowerCase().includes(selectedMuseum.toLowerCase())
        );
        setSearchedForArtworks(searchedWorks);
        setHasScrolled(true);
        handleScroll(firstImageRef.current);
      } catch (error) {
        console.log("this is error", error);
      }
    },
    [results]
  );

  useEffect(() => {
    if (firstImageRef.current) {
      firstImageRef.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  }, [searchedForArtworks]);

  useEffect(() => {
    if (scrollToTopRef.current) {
      scrollToTopRef.current.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  }, [hasScrolled]);

  const handleEnlarge = (result, index) => {
    setCurrentIndex(index);
    setImagedClicked(!imageClicked);
    setIndividualUrl(result.imageUrl);
  };

  const returnToNull = () => {
    setImagedClicked(false);
    setCurrentIndex(null);
  };

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 50 }} transition={{ duration: 2 }} exit={{ opacity: 0 }}>
      <div className='mobile-wrapper'>
        <MobileHeader />
      </div>
      <div className='desktop-wrapper'>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "transparent",
            position: "absolute",
            zIndex: "1000",
            width: "100vw",
          }}
        >
          <SplatterLogoDropdown background='transparent' />

          <div style={{ paddingRight: "22px", paddingTop: "30px" }}>
            <PollockProjectLogo />
          </div>
        </div>
      </div>
      <div className='desktop-wrapper' ref={scrollToTopRef}>
        <MapContainer
          center={[37.09024, -95.712891]}
          zoom={4}
          scrollWheelZoom={true}
          zoomControl={false}
          style={{ position: "relative" }}
        >
          <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
          {results.map((result, index) => {
            if (result.latitudeLongitude) {
              return (
                <Marker
                  position={[result.latitudeLongitude[0], result.latitudeLongitude[1]]}
                  eventHandlers={{
                    mouseover: (event) => event.target.openPopup(),
                    mouseout: (event) => event.target.closePopup(),
                    click: () => {
                      handleScroll(firstImageRef.current);
                      handleMuseumClick(result.museum);
                    },
                  }}
                >
                  <Popup>
                    <h3>{result.museum}</h3>
                    <p>
                      {result.city}, {result.state}
                    </p>
                  </Popup>
                </Marker>
              );
            }
          })}
        </MapContainer>
      </div>
      {/* ////////////// desltop up mobile down ///// */}
      <div className='mobile-wrapper' ref={scrollToTopRef}>
        <MapContainer
          center={[37.09024, -95.712891]}
          zoom={3}
          scrollWheelZoom={true}
          zoomControl={false}
          style={{ position: "relative", width: "100%", height: "300px" }}
        >
          <TileLayer url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
          {results.map((result, index) => {
            if (result.latitudeLongitude) {
              return (
                <Marker
                  key={index}
                  position={[result.latitudeLongitude[0], result.latitudeLongitude[1]]}
                  eventHandlers={{
                    click: () => {
                      handleScroll(firstImageRef.current);
                      handleMuseumClick(result.museum);
                    },
                  }}
                >
                  <Popup>
                    <h3>{result.museum}</h3>
                    <p>
                      {result.city}, {result.state}
                    </p>
                  </Popup>
                </Marker>
              );
            }
          })}
        </MapContainer>
      </div>
      <div ref={firstImageRef}>
        {searchedForArtworks && searchedForArtworks.length > 0 && (
          <div className='image-container'>
            <div className='mobile-wrapper'>
              <ul>
                {searchedForArtworks.map((result, index) => {
                  return (
                    <div key={result.id}>
                      <div className='image-and-text'>
                        <div
                          style={{
                            marginTop: 20,
                            marginBottom: 20,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            className='image'
                            src={result.imageUrl}
                            onClick={() => handleEnlarge(result, index)}
                            alt={"Not Available"}
                            object-fit='cover'
                            onDragStart={(e) => e.preventDefault()}
                          />
                        </div>
                        <div className='artwork-text'>
                          <div>
                            <h1
                              style={{
                                fontStyle: "italic",
                              }}
                            >
                              <b>{result.title}</b>
                            </h1>
                          </div>
                          <ul className='unordered'>
                            <li>Year –– {result.year}</li>
                            <li>Medium –– {result.medium}</li>
                            <li>Dimensions –– {result.dimensions}</li>
                            <li>
                              Museum ––
                              <a className='museum-link' href={result.link} target='_blank' rel='noopener noreferrer'>
                                {result.museum}
                              </a>
                            </li>
                            <li>
                              Location –– {result.city}, {result.state}, {result.country}
                            </li>
                            {result.onView && (
                              <li
                                style={{
                                  paddingBottom: "3px",
                                  fontSize: "15px",
                                  fontFamily: "Times New Roman",
                                }}
                              >
                                Currently on display
                              </li>
                            )}
                          </ul>
                          <div className='PK-copyright'>
                            &copy;The Pollock-Krasner Foundation / Artists Rights Society (ARS), New York
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </ul>
            </div>
            {/* mobile up desktop down */}
            <div className='desktop-wrapper' style={{ paddingTop: "80px", paddingBottom: "80px" }}>
              <div
                ref={firstImageRef}
                style={{
                  marginTop: "50px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <h3 style={{ color: "blue" }}>
                  There {searchedForArtworks.length === 1 ? "was" : "were"} {searchedForArtworks.length} Jackson Pollock{" "}
                  {searchedForArtworks.length === 1 ? "artwork" : "artworks"} found
                </h3>
              </div>
              <ul>
                {searchedForArtworks.map((result, index) => {
                  return (
                    <div key={result.id}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          paddingTop: "30px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: 20,
                            marginBottom: 20,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <img
                            src={result.imageUrl}
                            onClick={() => handleEnlarge(result, index)}
                            alt={"Not Available"}
                            object-fit='cover'
                            onDragStart={(e) => e.preventDefault()}
                            onMouseEnter={() => {
                              setHovering(true);
                              setCurrentIndex(index);
                            }}
                            onMouseOut={() => setHovering(false)}
                            style={{
                              userDrag: "none",
                              maxWidth: 400,
                              height: "auto",
                              border: hovering && currentIndex === index ? "2px solid blue" : "2px solid transparent",
                              padding: 3,
                            }}
                          />
                        </div>
                        <div style={{ paddingLeft: "480px", marginTop: "20px" }}>
                          <div>
                            <h1
                              style={{
                                fontStyle: "italic",
                                paddingBottom: "20px",
                                fontFamily: "Helvetica ",
                                fontSize: 20,
                              }}
                            >
                              <b>{result.title}</b>
                            </h1>
                          </div>
                          <ul style={{ paddingLeft: "40px" }}>
                            <li
                              style={{
                                paddingBottom: "3px",
                                fontSize: "15px",
                                fontFamily: "Times New Roman",
                              }}
                            >
                              Year –– {result.year}
                            </li>
                            <li
                              style={{
                                paddingBottom: "3px",
                                fontSize: "15px",
                                fontFamily: "Times New Roman",
                              }}
                            >
                              Medium –– {result.medium}
                            </li>
                            <li
                              style={{
                                paddingBottom: "3px",
                                fontSize: "15px",
                                fontFamily: "Times New Roman",
                              }}
                            >
                              Dimensions –– {result.dimensions}
                            </li>
                            <li
                              style={{
                                paddingBottom: "3px",
                                fontSize: "15px",
                                fontFamily: "Times New Roman",
                              }}
                            >
                              Museum ––{" "}
                              <a
                                href={result.link}
                                target='_blank'
                                rel='noopener noreferrer'
                                style={{
                                  color: "#0000FF",
                                  textDecoration: "none",
                                }}
                              >
                                {result.museum}
                              </a>
                            </li>
                            <li
                              style={{
                                paddingBottom: "3px",
                                fontSize: "15px",
                                fontFamily: "Times New Roman",
                              }}
                            >
                              Location –– {result.city}, {result.state}, {result.country}
                            </li>
                            {result.onView && (
                              <li
                                style={{
                                  paddingBottom: "3px",
                                  fontSize: "15px",
                                  fontFamily: "Times New Roman",
                                }}
                              >
                                Currently on display
                              </li>
                            )}
                          </ul>
                          <div style={{ paddingTop: "12px", fontSize: "15px", fontFamily: "Times New Roman" }}>
                            CR {result.catalog}
                          </div>
                          <div
                            style={{
                              paddingTop: "20px",
                              fontSize: "12px",
                              fontFamily: "Times New Roman",
                            }}
                          >
                            &copy; The Pollock-Krasner Foundation / Artists Rights Society (ARS), New York
                          </div>
                        </div>
                      </div>
                      {imageClicked && currentIndex === index && (
                        <ImageModal
                          returnToNull={returnToNull}
                          individualImageUrl={individualImageUrl}
                          setImagedClicked={setImagedClicked}
                          setCurrentIndex={setCurrentIndex}
                        />
                      )}
                    </div>
                  );
                })}
              </ul>
              <div className='mobile-wrapper' style={{ marginTop: "40px" }}>
                <p
                  style={{
                    fontSize: "12px",
                    paddingLeft: "46px",
                    fontFamily: "Helvetica",
                  }}
                >
                  &copy; 2025 The<b>Pollock</b>Project — All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      {searchedForArtworks.length > 0 && (
        <div
          style={{
            rotate: "-90deg",
            zIndex: 10,
            position: "fixed",
            bottom: "40px",
            right: "288px",
          }}
          onClick={() => {
            setHasScrolled(false);
            handleScroll(scrollToTopRef.current);
          }}
          className='desktop-wrapper'
        >
          <KeyboardTabIcon fontSize='large' style={{ color: "blue" }} />
        </div>
      )}
      {searchedForArtworks && searchedForArtworks.length > 0 && (
        <div className='desktop-wrapper'>
          <p
            style={{
              color: "black",
              fontSize: "12px",
              paddingTop: "20px",
              paddingBottom: "46px",
              paddingLeft: "50px",
              fontFamily: "Helvetica",
            }}
          >
            &copy; 2025 The<b>Pollock</b>Project — All Rights Reserved
          </p>
        </div>
      )}
    </motion.div>
  );
};

export default Map2;
