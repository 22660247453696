import React, { useEffect, useState } from "react";
import { doc, updateDoc, getDocs, collection, where, query } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { db, firestore, storage } from "../firebase_setup/firebase.config";

const AllArtworks = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [catalogValues, setCatalogValues] = useState({});

  const dataRef = collection(firestore, "pollockWorks");

  useEffect(() => {
    async function fetchData() {
      const pollockWorksRef = collection(db, "pollockWorks");
      const workInfo = query(pollockWorksRef, where("isArtwork", "==", true));
      const querySnapshot = await getDocs(workInfo);

      const promises = querySnapshot.docs.map(async (doc) => {
        const individualWork = doc.data();
        const url = individualWork.imagePath ? await getDownloadURL(ref(storage, individualWork.imagePath)) : "";

        return {
          id: doc.id,
          ...individualWork,
          imageUrl: url,
        };
      });

      const finalPromise = await Promise.all(promises);
      setResults(finalPromise);
    }
    fetchData();
  }, []);

  const handleInputChange = (id, value) => {
    setCatalogValues((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const updateCatalogHandler = async (id) => {
    const catalogValue = catalogValues[id];
    if (!catalogValue) {
      setError("Catalog value is required");
      return;
    }
    try {
      setLoading(true);
      const documentRef = doc(dataRef, id);
      await updateDoc(documentRef, { catalog: catalogValue });
      setLoading(false);
      setCatalogValues((prev) => ({ ...prev, [id]: "" }));
    } catch (error) {
      console.error("Error updating document: ", error);
      setLoading(false);
    }
  };

  return (
    <div>
      {results && results.length > 0 && (
        <div className='image-container'>
          <ul>
            {results.map((result) => {
              return (
                <div key={result.id}>
                  <div className='image-and-text'>
                    <div
                      style={{
                        marginTop: 20,
                        marginBottom: 20,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        className='image'
                        draggable='false'
                        src={result.imageUrl}
                        alt={"Not Available"}
                        object-fit='cover'
                        onDragStart={(e) => e.preventDefault()}
                      />
                    </div>
                    <div className='artwork-text'>
                      <div>
                        <h1
                          style={{
                            fontStyle: "italic",
                            fontFamily: "Helvetica",
                          }}
                        >
                          <b>{result.title}</b>
                        </h1>
                      </div>
                      <ul className='unordered'>
                        <li>Year –– {result.year}</li>
                        <li>Medium –– {result.medium}</li>
                        <li>Dimensions –– {result.dimensions}</li>
                        <li>
                          Museum ––
                          <a className='museum-link' href={result.link} target='_blank' rel='noopener noreferrer'>
                            {result.museum}
                          </a>
                        </li>
                        <li>
                          Location –– {result.city}, {result.state}, {result.country}
                        </li>
                        <li>On View –– {result.onView === true ? "Yes" : "No"}</li>
                        <li>CR –– {result.catalog}</li>
                      </ul>
                      <div>
                        <input
                          placeholder='CR #'
                          value={catalogValues[result.id] || ""}
                          onChange={(e) => handleInputChange(result.id, e.target.value)}
                        />
                        <button onClick={() => updateCatalogHandler(result.id)}>Submit</button>
                      </div>
                      <div className='PK-copyright'>
                        &copy;The Pollock-Krasner Foundation / Artists Rights Society (ARS), New York
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </ul>
        </div>
      )}
      {error && <div style={{ color: "red" }}>{error}</div>}
    </div>
  );
};

export default AllArtworks;
