import React from "react";
import MobileHeader from "./MobileHeader";
import SplatterLogoDropdown from "./SplatterLogoDropdown";
import PollockProjectLogo from "./PollockProjectLogo";
import SamInVan from "../assets/Sam_in_van_2025.jpeg";
import NewsletterInput from "./NewsletterInput";
import { motion } from "framer-motion";

const About = () => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 50 }} transition={{ duration: 2 }} exit={{ opacity: 0 }}>
      <div className='mobile-wrapper'>
        <MobileHeader />
      </div>
      <div className='desktop-wrapper'>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "white",
            position: "fixed",
            zIndex: "1",
            width: "100vw",
          }}
        >
          <SplatterLogoDropdown />
          <div style={{ paddingRight: "22px", paddingTop: "30px" }}>
            <PollockProjectLogo />
          </div>
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1,
          }}
        >
          <img
            src={SamInVan}
            draggable='false'
            alt=''
            height='280px'
            style={{ paddingRight: "20px", paddingTop: "4px" }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingLeft: "20px",
              width: "400px",
            }}
          >
            <p
              style={{
                fontFamily: '"Times New Roman", Times, serif',
                fontSize: "14px",
              }}
            >
              <span style={{ fontFamily: "Helvetica", fontSize: "12px" }}>
                The<b>Pollock</b>Project
              </span>{" "}
              is my multi-year journey, by van, to visit each of Jackson Pollock’s publicly displayed artworks
              throughout the United States.
            </p>
            <br />
            <p
              style={{
                fontFamily: '"Times New Roman", Times, serif',
                fontSize: "14px",
              }}
            >
              The purpose of this journey is to learn about an artwork's history and provenance, while also exploring
              the historiography of his varied mediums. I am also exploring the role of history, demography, popular
              artistic trends and economics in how Pollock’s work became so iconic and ultimately defining mid-century
              American modernism.
            </p>
            <br />
            <p
              style={{
                fontFamily: '"Times New Roman", Times, serif',
                fontSize: "14px",
              }}
            >
              I am meeting with museum directors, curators, educators, collectors, dealers and art enthusiasts across
              the country to assemble narratives and identify through-lines in the world that Jackson Pollock’s art has
              created.
            </p>
            <br />
            <p
              style={{
                fontFamily: '"Times New Roman", Times, serif',
                fontSize: "14px",
              }}
            >
              Fundamentally it’s about the journey and the quest, not the destination.
            </p>
            <br />
            <br />
            <p
              style={{
                fontFamily: "Helvetica",
                fontSize: "14px",
                marginBottom: "4px",
              }}
            >
              — Sam Seymour
            </p>
            <p>
              <a
                href='mailto:sam@thepollockproject.com'
                target='_blank'
                rel='noreferrer'
                style={{
                  fontFamily: "Helvetica",
                  fontSize: "14px",
                }}
              >
                sam@thepollockproject.com
              </a>
            </p>
            <br></br>
            <br></br>
            <p
              style={{
                fontFamily: "Helvetica",
                fontSize: "12px",
              }}
            >
              News
            </p>
            <a
              href='https://dartmouthalumnimagazine.com/articles/chasing-pollock'
              target='_blank'
              rel='noopener noreferrer'
              style={{ fontSize: "10.5px", color: "blue" }}
            >
              <i>"Chasing Pollock"</i> by Sam Seymour, Dartmouth Alumni Magazine, January — February 2025
            </a>
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          position: "fixed",
          bottom: "0",
          right: "0",
          zIndex: 1,
          paddingBottom: "50px",
          paddingRight: "300px",
        }}
      >
        <NewsletterInput />
      </div>
    </motion.div>
  );
};
export default About;
